<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <button class="btn btn-success" @click="connect">
          Connect to Xero
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
      code: this.$route.query.code,
    };
  },
  methods: {
    fetchCode() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/xero/code")
        .then(({ data }) => {
          console.log(data);
          this.code = data.code;
        });
    },
    connect() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/xero/connect")
        .then(({ data }) => {
          console.log(data);
          this.url = data.url;
          window.open(this.url, "_blank");
        });
    },
    sendCode() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/xero/connected", {
          code: this.code,
          state: this.$route.query.state,
        })
        .then(({ data }) => {
          console.log(data);
        });
    },
  },
  mounted() {
    if (this.$route.query.code) {
      this.sendCode();
    } else {
      this.fetchCode();
    }
  },
};
</script>

<style></style>
